import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Paper, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ViewListIcon from '@mui/icons-material/ViewList';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import banner from '../assets/img/A.png'; // Replace with your banner image path
import InstallAppButton from '../InstallAppButton';
import axios from 'axios';
const HomePage = () => {
  const [loading, setLoading] = useState(true);
 


  // Simulate loading for skeleton loader
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust time as needed
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={3}
      style={{ backgroundColor: '#f4f6f8', minHeight: '100vh' }}
    >
      {/* Install App Section */}
      <InstallAppButton />

      {/* Banner and Content Section */}
      <Paper
        elevation={3}
        style={{
          width: '100%',
          maxWidth: '800px',
          padding: '2rem',
          textAlign: 'center',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
        }}
      >
        {/* Skeleton Loader for Title */}
        {loading ? (
          <Skeleton
            variant="text"
            width={300}
            height={40}
            style={{ margin: '0 auto' }}
          />
        ) : (
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: '#3f51b5', fontWeight: 'bold' }}
          >
            Welcome to B2B eCommerce
          </Typography>
        )}

        {/* Skeleton Loader for Subtitle */}
        {loading ? (
          <Skeleton
            variant="text"
            width={250}
            height={30}
            style={{ margin: '1rem auto' }}
          />
        ) : (
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginBottom: '2rem' }}
          >
            Your one-stop solution for all B2B transactions
          </Typography>
        )}

        {/* Banner Image with Skeleton */}
        <Box display="flex" justifyContent="center" mb={3}>
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={180}
              style={{ borderRadius: '8px' }}
            />
          ) : (
            <img
              src={banner}
              alt="Banner"
              style={{ width: '100%', borderRadius: '8px' }}
            />
          )}
        </Box>

        {/* Buttons Section with Large Icons and Box Style */}
        <Box display="flex" justifyContent="space-around" flexWrap="wrap" mt={3} gap={2}>
          {loading ? (
            <>
              <Skeleton variant="rectangular" width="45%" height={120} style={{ borderRadius: '8px' }} />
              <Skeleton variant="rectangular" width="45%" height={120} style={{ borderRadius: '8px' }} />
              <Skeleton variant="rectangular" width="45%" height={120} style={{ borderRadius: '8px' }} />
              <Skeleton variant="rectangular" width="45%" height={120} style={{ borderRadius: '8px' }} />
            </>
          ) : (
            <>
              {/* Search Products Button */}
              <Box
                component={Link}
                to="/search"
                style={{
                  width: '45%',
                  height: '120px',
                  backgroundColor: '#e3f2fd',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <SearchIcon style={{ fontSize: '50px', color: '#2196f3' }} />
                <Typography variant="h6" style={{ color: '#2196f3', fontWeight: 'bold', marginTop: '10px' }}>
                  Search Products
                </Typography>
              </Box>

              {/* View Cart Button */}
              <Box
                component={Link}
                to="/cart"
                style={{
                  width: '45%',
                  height: '120px',
                  backgroundColor: '#f3e5f5',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <ShoppingCartIcon style={{ fontSize: '50px', color: '#9c27b0' }} />
                <Typography variant="h6" style={{ color: '#9c27b0', fontWeight: 'bold', marginTop: '10px' }}>
                  View Cart
                </Typography>
              </Box>

              {/* View Orders Button */}
              <Box
                component={Link}
                to="/orders"
                style={{
                  width: '45%',
                  height: '120px',
                  backgroundColor: '#e8f5e9',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <ViewListIcon style={{ fontSize: '50px', color: '#4caf50' }} />
                <Typography variant="h6" style={{ color: '#4caf50', fontWeight: 'bold', marginTop: '10px' }}>
                  View Orders
                </Typography>
              </Box>

              {/* View Ledger Button */}
              <Box
                component={Link}
                to="/ledger"
                style={{
                  width: '45%',
                  height: '120px',
                  backgroundColor: '#fce4ec',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <AccountBalanceWalletIcon style={{ fontSize: '50px', color: '#e91e63' }} />
                <Typography variant="h6" style={{ color: '#e91e63', fontWeight: 'bold', marginTop: '10px' }}>
                  View Ledger
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default HomePage;
