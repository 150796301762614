import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Badge, Typography, Box, Drawer, Button } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import LoginPage from './components/LoginPage';
import Sidebar from './components/Sidebar';
import HomePage from './components/HomePage';
import ProductSearch from './components/ProductSearch';
import Cart from './components/Cart';
import ViewOrder from './components/ViewOrder';
import OrderDetail from './components/OrderDetail';
import { fetchAndStoreProducts } from './utils/fetchProducts';


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [customer, setCustomer] = useState(null);
  const sidebarRef = useRef(null);
  const [products, setProducts] = useState(null);
  useEffect(() => {
    fetchAndStoreProducts();
  }, []);
  useEffect(() => {
    if (window.location.pathname === "/") {
      window.location.replace("/home");
    }
  }, []);

  useEffect(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }

    const storedCustomer = localStorage.getItem('customer');
    if (storedCustomer) {
      setCustomer(JSON.parse(storedCustomer));
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const handleLogin = (customerData) => {
    setCustomer(customerData);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('customer');
    localStorage.removeItem('cartItems');
    setCustomer(null);
    setIsLoggedIn(false);
    setCartItems([]);
  };

  const handleAddToCart = (product) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find((item) => item.product_id === product.product_id);
      if (existingItem) {
        return prevItems.map((item) =>
          item.product_id === product.product_id ? { ...item, quantity: product.quantity } : item
        );
      }
      return [...prevItems, product];
    });
  };

  const handleUpdateCartItem = (updatedProduct) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.product_id === updatedProduct.product_id ? updatedProduct : item
      )
    );
  };

  const handleRemoveFromCart = (productId) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.product_id !== productId));
  };

  const handleClearCart = () => {
    setCartItems([]);
  };

  const cartItemCount = cartItems.reduce((count, item) => count + item.quantity, 0);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  if (!isLoggedIn) {
    return <LoginPage onLogin={handleLogin} />;
  }

  return (
    <Router>
      <div style={{ display: 'flex' }}>
        <Drawer
          variant="persistent"
          anchor="left"
          open={isSidebarOpen}
          ref={sidebarRef}
          PaperProps={{
            style: {
              width: '250px',
              padding: '1rem',
              backgroundColor: '#f0f0f0',
            }
          }}
        >
          <Sidebar onClose={toggleSidebar} customer={customer} onLogout={handleLogout} />
        </Drawer>
        <div style={{ width: '100%' }}>
          <AppBar position="static">
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleSidebar}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" style={{ flexGrow: 1 }}>
                B2B eCommerce
              </Typography>
              
              <IconButton component={Link} to="/cart" color="inherit">
                <Badge badgeContent={cartItemCount} color="secondary">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box p={3}>
            <Routes>
              <Route path="/" element={<Navigate to="/home" />}  />  {/* Redirect root to home */}
              <Route path="/home" element={<HomePage  />} /> {/* Default Home Page */}
              <Route path="/search" element={<ProductSearch onAddToCart={handleAddToCart} cartItems={cartItems || []} products={products} setProducts={setProducts}  onRemoveFromCart={handleRemoveFromCart} onUpdateCartItem={handleUpdateCartItem} customer={customer} onClearCart={handleClearCart}/>} />
              <Route path="/cart" element={<Cart cartItems={cartItems} onRemoveFromCart={handleRemoveFromCart} onUpdateCartItem={handleUpdateCartItem} customer={customer} onClearCart={handleClearCart} />} />
              <Route path="/orders" element={<ViewOrder customer={customer} />} />
              <Route path="/order/:orderId" element={<OrderDetail />} />
              <Route path="/ledger" element={<div><h2>Ledger Page</h2><p>This is a dummy ledger page.</p></div>} />
              <Route path="*" element={<Navigate to="/home" />} /> {/* Redirect all unknown paths to home */}
            </Routes>
          </Box>
        </div>
      </div>
    </Router>
  );
};

export default App;
