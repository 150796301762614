import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { DataProvider } from './DataContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <DataProvider>
      <App />
    </DataProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// Unregister all old service workers
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => registration.unregister());  // Unregister all service workers
  });
}

// Ensure no service worker is registered in the app
serviceWorkerRegistration.unregister();  // Prevent any new service worker registration
