import React from 'react';
import { useNavigate } from 'react-router-dom';
import { List, Button, IconButton, Box, Paper, Typography, Divider, TextField, useMediaQuery } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PercentIcon from '@mui/icons-material/Percent';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DiscountIcon from '@mui/icons-material/Discount';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

const Cart = ({ cartItems, onRemoveFromCart, onUpdateCartItem, onClearCart, customer }) => {
  
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if it's a mobile view

  const incrementQty = (product) => {
    onUpdateCartItem({ ...product, quantity: product.quantity + 1 });
  };

  const decrementQty = (product) => {
    if (product.quantity > 1) {
      onUpdateCartItem({ ...product, quantity: product.quantity - 1 });
    } else {
      onRemoveFromCart(product.product_id);
    }
  };

  const calculateTotal = (ptr, quantity, gst, scheme) => {
    const baseTotal = ptr * quantity;

    let schemeDiscount = 0;
    if (scheme && scheme.includes('%')) {
      const schemePercentage = parseFloat(scheme.replace('%', '')); 
      if (!isNaN(schemePercentage)) {
        schemeDiscount = (baseTotal * schemePercentage) / 100;
      }
    }

    const discountedTotal = baseTotal - schemeDiscount;
    const gstAmount = (discountedTotal * gst) / 100;
    const finalTotal = discountedTotal + gstAmount;

    return {
      baseTotal: baseTotal.toFixed(2),
      schemeDiscount: schemeDiscount.toFixed(2),
      discountedTotal: discountedTotal.toFixed(2),
      gstAmount: gstAmount.toFixed(2),
      finalTotal: finalTotal.toFixed(2),
    };
  };

  const grandTotalDetails = cartItems.reduce(
    (acc, item) => {
      const { baseTotal, schemeDiscount, discountedTotal, gstAmount, finalTotal } = calculateTotal(item.ptr, item.quantity, item.gst, item.scheme);
      acc.grossAmount += parseFloat(baseTotal);
      acc.discountedAmount += parseFloat(schemeDiscount);
      acc.gstAmount += parseFloat(gstAmount);
      acc.finalTotal += parseFloat(finalTotal);
      return acc;
    },
    { grossAmount: 0, discountedAmount: 0, gstAmount: 0, finalTotal: 0 }
  );

  const grandTotalFormatted = {
    grossAmount: grandTotalDetails.grossAmount.toFixed(2),
    discountedAmount: grandTotalDetails.discountedAmount.toFixed(2),
    gstAmount: grandTotalDetails.gstAmount.toFixed(2),
    finalTotal: grandTotalDetails.finalTotal.toFixed(2),
  };

  const handlePlaceOrder = () => {
    const orderDetails = cartItems.map((item) => {
      const { baseTotal, schemeDiscount, gstAmount, finalTotal } = calculateTotal(item.ptr, item.quantity, item.gst, item.scheme);
      return {
        product_id: item.product_id,
        prod_id: item.product_id,
        prod_name_to_display: item.product_name,
        prod_conv1: '', // Assuming no value is needed for these fields
        prod_unit2: '',
        mkt_group: '',
        product_mrp: item.ptr, // PTR value
        qty: item.quantity,
        gst_code: gstAmount, // GST amount
        discount_percentage: item.scheme ? parseFloat(item.scheme.replace('%', '')) : 0, // Scheme percentage
        discount_amount: schemeDiscount, // Scheme discount amount
        final_amount: finalTotal, // Final total after scheme discount and GST
      };
    });

    const orderData = {
      customer_id: customer.customer_id,
      coupon_code: '', // Assuming no coupon is being used
      gross_amount: grandTotalFormatted.grossAmount, // Sum of base amounts
      discounted_amount: grandTotalFormatted.discountedAmount, // Total scheme discount amount
      coupon_discount: grandTotalFormatted.couponDiscount, // Total GST amount
      amount: grandTotalFormatted.finalAmount, // Final total amount
      customer: customer.id, // Customer's internal ID
      customer_address: customer.address,
      quantity: cartItems.reduce((sum, item) => sum + item.quantity, 0), // Total quantity of all products
      order_details: orderDetails,
    };

    axios
      .post('https://avinandanserampore.com/api/place_order.php', orderData)
      .then((response) => {
        alert('Order placed successfully!');
        console.log(orderData);
        console.log('Response from API:', response); // Print the full response
    console.log('Response data:', response.data); 
        localStorage.removeItem('cartItems'); // Clear cart items from local storage
        onClearCart(); // Clear all items from the cart state
        navigate('/orders'); // Redirect to ViewOrder page
      })
      .catch((error) => {
        console.error('There was an error placing the order!', error);
      });
  };

  return (
    <div>
      <List>
        {cartItems.map((product) => {
          const {
            baseTotal,
            schemeDiscount,
            discountedTotal,
            gstAmount,
            finalTotal,
          } = calculateTotal(product.ptr, product.quantity, product.gst, product.scheme);

          return (
            <Paper
              elevation={3}
              style={{
                marginBottom: '1rem',
                transition: 'transform 0.3s',
                border: '1px solid #ccc',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              key={product.product_id}
            >
              <Box display="flex" flexDirection="column">
                {/* Product Header */}
                <Box
                  style={{
                    backgroundColor: '#4a90e2',
                    padding: '0.5rem 1rem',
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                  }}
                >
                  <Typography variant="h6" style={{ fontWeight: 'bold', color: 'white', textAlign: 'left' }}>
                    {product.product_name}
                  </Typography>
                </Box>

                {/* Quantity Control Section */}
                <Box display="flex" alignItems="center" justifyContent="center" padding="1rem">
                  <IconButton
                    onClick={() => decrementQty(product)}
                    style={{ color: '#ff5722', transition: 'transform 0.3s' }}
                    className="decrement-button"
                  >
                    <RemoveIcon />
                  </IconButton>
                  <TextField
                    value={product.quantity}
                    style={{ width: '50px', margin: '0 10px' }}
                    inputProps={{ min: 0, style: { textAlign: 'center' }, readOnly: true }}
                    margin="normal"
                    disabled
                  />
                  <IconButton
                    onClick={() => incrementQty(product)}
                    style={{ color: '#4caf50', transition: 'transform 0.3s' }}
                    className="increment-button"
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => onRemoveFromCart(product.product_id)}
                    style={{ color: '#f44336', marginLeft: '10px' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>

                {/* Calculation Breakdown Section */}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap={isMobile ? 'wrap' : 'nowrap'}
                  padding="0.5rem 1rem"
                  style={{
                    backgroundColor: '#e0e0e0',
                    borderRadius: '4px',
                    margin: '0.5rem',
                    textAlign: 'center',
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <CurrencyRupeeIcon fontSize="small" />
                    <Typography variant="body2" style={{ marginLeft: '5px' }}>
                      ₹{baseTotal}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <LocalOfferIcon fontSize="small" />
                    <Typography variant="body2" style={{ marginLeft: '5px' }}>
                      - ₹{schemeDiscount}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <AttachMoneyIcon fontSize="small" />
                    <Typography variant="body2" style={{ marginLeft: '5px' }}>
                      ₹{discountedTotal}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <PercentIcon fontSize="small" />
                    <Typography variant="body2" style={{ marginLeft: '5px' }}>
                      GST: ₹{gstAmount}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <AttachMoneyIcon fontSize="small" />
                    <Typography variant="body2" style={{ marginLeft: '5px', fontWeight: 'bold' }}>
                      ₹{finalTotal}
                    </Typography>
                  </Box>
                </Box>

                {/* Product Details Footer */}
                <Divider style={{ width: '100%' }} />
                <Box
                  style={{
                    backgroundColor: '#f5f5f5',
                    padding: '0.5rem 1rem',
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px',
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      color: '#333',
                      textAlign: 'left',
                    }}
                  >
                    MRP: ₹{product.mrp} | PTR: ₹{product.ptr} | GST: {product.gst}% | Scheme: {product.scheme || 'N/A'}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          );
        })}
      </List>

      {/* Grand Total Section */}
      <Box
        style={{
          marginTop: '1rem',
          padding: '1rem',
          backgroundColor: '#e0e0e0',
          borderRadius: '4px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          textAlign: 'right',
        }}
      >
        <Typography variant="h6" style={{ marginBottom: '1rem', fontWeight: 'bold' }}>
          Grand Total Summary
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="flex-end" gap="0.5rem">
          <Box display="flex" alignItems="center">
            <ShoppingCartIcon fontSize="small" style={{ marginRight: '5px' }} />
            <Typography variant="body2">Gross Amount: ₹{grandTotalFormatted.grossAmount}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <DiscountIcon fontSize="small" style={{ marginRight: '5px' }} />
            <Typography variant="body2">Discounted Amount: ₹{grandTotalFormatted.discountedAmount}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <PercentIcon fontSize="small" style={{ marginRight: '5px' }} />
            <Typography variant="body2">GST Amount: ₹{grandTotalFormatted.gstAmount}</Typography>
          </Box>
        </Box>
      </Box>

      {/* Final Grand Total Section */}
      <Box
        style={{
          marginTop: '1rem',
          padding: '1rem',
          backgroundColor: '#4caf50',
          color: 'white',
          textAlign: 'right',
          fontWeight: 'bold',
          borderRadius: '4px',
        }}
      >
        <Typography variant="h6">Final Grand Total: ₹{grandTotalFormatted.finalTotal}</Typography>
      </Box>

      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '1rem', float: 'right' }}
        onClick={handlePlaceOrder}
      >
        Place Order
      </Button>
    </div>
  );
};

export default Cart;
